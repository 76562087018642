import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-padel-live-results',
  templateUrl: './event-padel-live-results.component.html',
  styleUrls: ['./event-padel-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPadelLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '93', '94', '95', '96', '97', '98', '80', '90'];

  protected hTG: number;
  protected aTG: number;

  protected pLS: EventPeriodScore;

  protected hT: number;
  protected aT: number;

  protected s: string;

  protected getEndedCodes(): string[] {
    return EventPadelLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    this.s = this.matchProperties.currentserver;
    this.hTG = Number(this.matchProperties.homegamescore) || 0;
    this.aTG = Number(this.matchProperties.awaygamescore) || 0;
    this.pLS = this.scores[this.scores.length - 1];
    this.hT = Number(this.matchProperties.homescore);
    this.aT = Number(this.matchProperties.awayscore);
  }

}
