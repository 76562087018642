@if (ended) {
  <div class='scp-event-aussie-rules-live-results'>
    <div class='results-grid columns-1' [class.without-border]='!border'>
      <span class='header'>M</span>
      <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
      <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
    </div>
  </div>
} @else if (!ended && scores.length) {
  <div class='scp-event-aussie-rules-live-results'>
    @switch (scores.length) {
      @case (1) {
        <div class='results-grid columns-2' [class.without-border]='!border'>
          <span class='header'>1Q</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1st.homeScore > p1st.awayScore'>{{ p1st.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p1st.homeScore < p1st.awayScore'>{{ p1st.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (2) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>1Q</span>
          <span class='header'>2Q</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1st.homeScore > p1st.awayScore'>{{ p1st.homeScore }}</span>
          <span [class.highlighted-cell]='p2nd.homeScore > p2nd.awayScore'>{{ p2nd.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p1st.homeScore < p1st.awayScore'>{{ p1st.awayScore }}</span>
          <span [class.highlighted-cell]='p2nd.homeScore < p2nd.awayScore'>{{ p2nd.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (3) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>2Q</span>
          <span class='header'>3Q</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p2nd.homeScore > p2nd.awayScore'>{{ p2nd.homeScore }}</span>
          <span [class.highlighted-cell]='p3rd.homeScore > p3rd.awayScore'>{{ p3rd.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p2nd.homeScore < p2nd.awayScore'>{{ p2nd.awayScore }}</span>
          <span [class.highlighted-cell]='p3rd.homeScore < p3rd.awayScore'>{{ p3rd.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (4) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>3Q</span>
          <span class='header'>4Q</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p3rd.homeScore > p3rd.awayScore'>{{ p3rd.homeScore }}</span>
          <span [class.highlighted-cell]='p4th.homeScore > p4th.awayScore'>{{ p4th.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p3rd.homeScore < p3rd.awayScore'>{{ p3rd.awayScore }}</span>
          <span [class.highlighted-cell]='p4th.homeScore < p4th.awayScore'>{{ p4th.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (5) {
        <div class='results-grid columns-2' [class.without-border]='!border'>
          <span class='header'>1OT</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1stOT.homeScore > p1stOT.awayScore'>{{ p1stOT.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p1stOT.homeScore < p1stOT.awayScore'>{{ p1stOT.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (6) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>1OT</span>
          <span class='header'>2OT</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1stOT.homeScore > p1stOT.awayScore'>{{ p1stOT.homeScore }}</span>
          <span [class.highlighted-cell]='p2ndOT.homeScore > p2ndOT.awayScore'>{{ p2ndOT.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='p1stOT.homeScore < p1stOT.awayScore'>{{ p1stOT.awayScore }}</span>
          <span [class.highlighted-cell]='p2ndOT.homeScore < p2ndOT.awayScore'>{{ p2ndOT.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
      @case (7) {
        <div class='results-grid columns-2' [class.without-border]='!border'>
          <span class='header'>SD</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='pSD.homeScore > pSD.awayScore'>{{ pSD.homeScore }}</span>
          <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
          <span [class.highlighted-cell]='pSD.homeScore < pSD.awayScore'>{{ pSD.awayScore }}</span>
          <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
        </div>
      }
    }
  </div>
}
