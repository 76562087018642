import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';
import { MARKETS_ALPINE_SKIING } from './alpine-skiing';
import { MARKETS_AMERICAN_FOOTBALL } from './american-football';
import { MARKETS_AUSSIE_RULES } from './aussie-rules';
import { MARKETS_BADMINTON } from './badminton';
import { MARKETS_BANDY } from './bandy';
import { MARKETS_BASEBALL } from './baseball';
import { MARKETS_BASKETBALL, MARKETS_BASKETBALL_3x3 } from './basketball';
import { MARKETS_BEACH_VOLLEYBALL } from './beach-volleyball';
import { MARKETS_BIATHLON } from './biathlon';
import { MARKETS_BOWLS } from './bowls';
import { MARKETS_BOXING } from './boxing';
import { MARKETS_COUNTER_STRIKE } from './counter-strike';
import { MARKETS_CRICKET } from './cricket';
import { MARKETS_DARTS } from './darts';
import { MARKETS_DOTA } from './dota';
import { MARKETS_FIELD_HOKEY } from './field-hockey';
import { MARKETS_FORMULA_1 } from './formula-1';
import { MARKETS_FORMULA_E } from './formula-e';
import { MARKETS_FUTSAL } from './futsal';
import { MARKETS_GOLF } from './golf';
import { MARKETS_HANDBALL } from './handball';
import { MARKETS_ICE_HOKEY } from './ice-hockey';
import { MARKETS_KABADDI } from './kabaddi';
import { MARKETS_LEAGUE_OF_LEGENDS } from './league-of-legends';
import { MARKETS_MOTO_GP } from './moto-gp';
import { MARKETS_MMA } from './mma';
import { MARKETS_PADEL } from './padel';
import { MARKETS_RUGBY } from './rugby';
import { MARKETS_SKI_JUMPING } from './ski-jumping';
import { MARKETS_SNOOKER } from './snooker';
import { MARKETS_SOCCER } from './soccer';
import { MARKETS_SQUASH } from './squash';
import { MARKETS_TABLE_TENNIS } from './table-tennis';
import { MARKETS_TENNIS } from './tennis';
import { MARKETS_VOLLEYBALL } from './volleyball';
import { MARKETS_WATERPOLO } from './waterpolo';
import { MARKETS_FLOORBALL } from '@scpc/modules/sports/components/event-top-markets/sports/floorball';
import { MARKETS_RALLY } from '@scpc/modules/sports/components/event-top-markets/sports/rally';
import { MARKETS_BEACH_SOCCER } from '@scpc/modules/sports/components/event-top-markets/sports/beach-soccer';


export const TOP_MARKETS: Map<string, MarketLayout[]> = new Map([
  MARKETS_ALPINE_SKIING,
  MARKETS_AMERICAN_FOOTBALL,
  MARKETS_AUSSIE_RULES,
  MARKETS_BADMINTON,
  MARKETS_BANDY,
  MARKETS_BASEBALL,
  MARKETS_BASKETBALL,
  MARKETS_BASKETBALL_3x3,
  MARKETS_BEACH_SOCCER,
  MARKETS_BEACH_VOLLEYBALL,
  MARKETS_BIATHLON,
  MARKETS_BOWLS,
  MARKETS_BOXING,
  MARKETS_COUNTER_STRIKE,
  MARKETS_CRICKET,
  MARKETS_DARTS,
  MARKETS_DOTA,
  MARKETS_FIELD_HOKEY,
  MARKETS_FLOORBALL,
  MARKETS_FORMULA_1,
  MARKETS_FORMULA_E,
  MARKETS_FUTSAL,
  MARKETS_GOLF,
  MARKETS_HANDBALL,
  MARKETS_ICE_HOKEY,
  MARKETS_KABADDI,
  MARKETS_LEAGUE_OF_LEGENDS,
  MARKETS_MMA,
  MARKETS_MOTO_GP,
  MARKETS_PADEL,
  MARKETS_RUGBY,
  MARKETS_SKI_JUMPING,
  MARKETS_SNOOKER,
  MARKETS_SOCCER,
  MARKETS_SQUASH,
  MARKETS_TABLE_TENNIS,
  MARKETS_TENNIS,
  MARKETS_VOLLEYBALL,
  MARKETS_WATERPOLO,
  MARKETS_RALLY,
]);
