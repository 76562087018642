@if (scores.length) {
  <div class='scp-event-field-hockey-live-results'>
    @if (scores[0].matchStatusCode === 13) {
      @if (scores.length === 1) {
        <div class='results-grid columns-2' [class.without-border]='!border'>
          <span class='header'>{{ p2.periodNumber + 'Q' }}</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
          <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
          <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
          <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
        </div>
      } @else if (scores.length > 1 && scores.length <= 4) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>{{ p1.periodNumber + 'Q' }}</span>
          <span class='header'>{{ p2.periodNumber + 'Q' }}</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1.homeScore > p1.awayScore'>{{ p1.homeScore }}</span>
          <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
          <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
          <span [class.highlighted-cell]='p1.homeScore < p1.awayScore'>{{ p1.awayScore }}</span>
          <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
          <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
        </div>
      }
    } @else if (scores[0].matchStatusCode === 6) {
      @if (scores.length === 1) {
        <div class='results-grid columns-2' [class.without-border]='!border'>
          <span class='header'>{{ p2.periodNumber + 'H' }}</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
          <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
          <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
          <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
        </div>
      } @else if (scores.length === 2) {
        <div class='results-grid columns-3' [class.without-border]='!border'>
          <span class='header'>{{ p1.periodNumber + 'H' }}</span>
          <span class='header'>{{ p2.periodNumber + 'H' }}</span>
          <span class='header'>M</span>
          <span [class.highlighted-cell]='p1.homeScore > p1.awayScore'>{{ p1.homeScore }}</span>
          <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
          <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
          <span [class.highlighted-cell]='p1.homeScore < p1.awayScore'>{{ p1.awayScore }}</span>
          <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
          <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
        </div>
      }
    }
    @if (p2?.matchStatusCode === 40) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>OT</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    } @else if (p2?.matchStatusCode === 50) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>P</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    } @else if (p2?.matchStatusCode === 440) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>SD</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    }
  </div>
}
