import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
} from '@angular/core';
import { Event } from './../../dto';
import {
  EventTopMarketsComponent,
} from '@scpc/modules/sports/components/event-top-markets/event-top-markets.component';
import { EventCardComponent } from '@scpc/modules/sports/components/event-card/event-card.component';
import { TournamentIconComponent } from '@scpc/modules/sports/components/tournament-icon/tournament-icon.component';
import { RouterLink } from '@angular/router';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { VisibilityDirective } from '@scpc/modules/common/directives/visibility.directive';

@Component({
  selector: 'scp-sports-event-row',
  templateUrl: './event-row.component.html',
  styleUrls: ['./event-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    EventCardComponent,
    EventTopMarketsComponent,
    TournamentIconComponent,
    VisibilityDirective,
  ],
})
export class EventRowComponent {

  @Input()
  public event: Event;

  @Input()
  public tournament = false;

  @Input()
  public isBrowser: boolean;

  @Input()
  public mini = false;

  @Input()
  public search = false;

  @Input()
  public source: string;

  constructor(protected readonly googleTagManagerService: GoogleTagManagerService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly zone: NgZone,
  ) {
  }

  public update(): void {
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  }

  /* istanbul ignore next */
  protected onShow(visible: boolean): void {
    if (visible) {
      this.googleTagManagerService.viewEventsItemList([this.event], this.source);
    }
  }

}
