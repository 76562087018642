import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_BASEBALL: [string, MarketLayout[]] = [
  'sr:sport:3',
  [
    {
      market: { id: '251', name: 'Winner (incl. extra innings)' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '' },
        { id: '5', selector: 'outcome-type-5', name: '' }
      ]
    },
    {
      market: { id: '258', name: 'Total (incl. extra innings)' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: '256', name: 'Handicap (incl. extra innings)' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' }
      ]
    }
  ]
];
