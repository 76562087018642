import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_COUNTER_STRIKE: [string, MarketLayout[]] = [
  'sr:sport:109',
  [
    {
      market: { id: '186', name: 'Winner' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '1' },
        { id: '5', selector: 'outcome-type-5', name: '2' },
      ]
    },
    {
      market: { id: '328', name: 'Total maps' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: '327', name: 'Map handicap' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' }
      ]
    }
  ]
];
