import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventCategory, EventSport, EventTournament } from '@scpc/modules/sports/dto';
import { TournamentImagePipe } from '@scpc/modules/sports/pipes/tournament-image';
import { DefaultTournamentImage } from '@scpc/modules/sports/pipes/default-tournament-image';
import { ImgFallbackDirective } from '@scpc/modules/common/directives/img-fallback.derictive';

@Component({
  selector: 'scp-sports-tournament-icon',
  templateUrl: './tournament-icon.component.html',
  styleUrls: ['./tournament-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TournamentImagePipe, DefaultTournamentImage, ImgFallbackDirective],
})
export class TournamentIconComponent {

  @Input()
  public sport: EventSport;

  @Input()
  public category: EventCategory;

  @Input()
  public tournament: EventTournament;

  @Input()
  public title: boolean = true;

}
