import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';

@Component({
  selector: 'scp-sports-event-ski-jumping-live-results',
  templateUrl: './event-ski-jumping-live-results.component.html',
  styleUrls: ['./event-ski-jumping-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSkiJumpingLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = [];

  protected getEndedCodes(): string[] {
    return EventSkiJumpingLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    // TODO (alpine-skiing): show results
  }

}
