import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { VisibilityService } from '@scpc/modules/common/services/visibility.service';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[scpVisibility]',
})
export class VisibilityDirective implements AfterViewInit, OnChanges, OnDestroy {

  @Input()
  public visibilityMargin: string = '0px';

  @Input()
  public visibilityThreshold: number | number[] = 0;

  @Output('scpVisibility')
  public visibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  private observing = false;
  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private readonly elementRef: ElementRef,
    private readonly visibilityService: VisibilityService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.observe();
  }

  /* istanbul ignore next */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.observing && (changes.visibilityMargin || changes.visibilityThresholds || changes.visibility)) {
      this.unobserve();
      this.observe();
    }
  }

  public ngOnDestroy(): void {
    this.unobserve();
  }

  private observe(): void {
    if (!this.observing && this.isBrowser) {
      const config: IntersectionObserverInit = {
        rootMargin: '0px',
        root: null,
        threshold: [0],
      };

      if (this.visibilityMargin) {
        config.rootMargin = this.visibilityMargin;
      }

      /* istanbul ignore next */
      if (this.visibilityThreshold) {
        if (Array.isArray(this.visibilityThreshold)) {
          config.threshold = this.visibilityThreshold;
        } else {
          config.threshold = [this.visibilityThreshold];
        }
      }

      this.visibilityService.observe(this.elementRef.nativeElement, (isVisible: boolean): void => {
          this.visibility.emit(isVisible);
        },
        config,
      );
      this.observing = true;
    }
  }

  private unobserve(): void {
    if (this.observing) {
      this.visibilityService.unobserve(this.elementRef.nativeElement);
      this.observing = false;
    }
  }

}
