@if (scores.length) {
  @if (!p1) {
    <div class='scp-event-american-football-live-results'>
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>{{ p2.periodNumber <= 4 ? p2.periodNumber + 'Q' : "OT" }}</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    </div>
  } @else {
    <div class='scp-event-american-football-live-results'>
      <div class='results-grid columns-3' [class.without-border]='!border'>
        <span class='header'>{{ p1.periodNumber <= 4 ? p1.periodNumber + 'Q' : "OT" }}</span>
        <span class='header'>{{ p2.periodNumber <= 4 ? p2.periodNumber + 'Q' : "OT" }}</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p1.homeScore > p1.awayScore'>{{ p1.homeScore }}</span>
        <span [class.highlighted-cell]='p2.homeScore > p2.awayScore'>{{ p2.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='p1.homeScore < p1.awayScore'>{{ p1.awayScore }}</span>
        <span [class.highlighted-cell]='p2.homeScore < p2.awayScore'>{{ p2.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    </div>
  }
}
