import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_CRICKET: [string, MarketLayout[]] = [
  'sr:sport:21',
  [
    {
      market: { id: '340', name: 'Winner (incl. super over)' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '1' },
        { id: '5', selector: 'outcome-type-5', name: '2' }
      ]
    },
    {
      market: { id: '356', name: '', alternativeId: '357' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: '352', name: '', alternativeId: '353' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    }
  ]
];
