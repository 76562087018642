import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Event, EventMatchProperties, EventPeriodScore } from './../../dto';

@Directive()
export abstract class EventLiveResultsComponent implements OnChanges {

  @Input()
  public event: Event;

  @Input()
  public scores: EventPeriodScore[];

  @Input()
  public matchProperties?: EventMatchProperties;

  @Input()
  public border: boolean;

  protected ended: boolean;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('matchProperties' in changes) {
      this.ended = this.isEnded();
    }
    /* istanbul ignore next */
    if ('matchProperties' in changes || 'scores' in changes) {
      this.updateScore();
    }
  }

  protected isEnded(): boolean {
    /* istanbul ignore next */
    return this.getEndedCodes().includes(this.matchProperties?.matchstatusid);
  }

  protected abstract updateScore();

  protected abstract getEndedCodes(): string[];

}
