import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_MMA: [string, MarketLayout[]] = [
  'sr:sport:117',
  [
    {
      market: { id: '186', name: 'Winner' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '1' },
        { id: '5', selector: 'outcome-type-5', name: '2' }
      ]
    },
    {
      market: { id: '18', name: 'Total' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: 'm1', name: '' },
      outcomes: [
        { id: 'o1', selector: 'outcome-type-o1', name: '' },
        { id: 'o2', selector: 'outcome-type-o2', name: '' }
      ]
    }
  ]
];
