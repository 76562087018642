<div class='scp-sports-event-card'
     [class.live]='event.live'
     [class.single]='event.season'
     [class.prematch-responsive]='responsive && !event.live'
     itemscope itemtype='https://schema.org/SportsEvent'>
  <meta itemprop='image' content='{{event.tournament.id | tournamentImage}}' />
  <meta itemprop='startDate' content='{{event.scheduleTime | date: "yyyy-MM-ddTHH:mmZZZZZ" : "UTC+2": "en" }}' />
  <meta itemprop='eventStatus' content='https://schema.org/EventScheduled' />
  <meta itemprop='eventAttendanceMode' content='https://schema.org/OnlineEventAttendanceMode' />
  <span itemscope itemtype='https://schema.org/VirtualLocation' itemprop='location'>
    <meta itemprop='url' content='/sports/events/{{event.slug}}'>
  </span>
  <span itemscope itemtype='https://schema.org/PerformingGroup' itemprop='performer'>
    <meta itemprop='name' content='YesPlay.bet'>
  </span>
  @if (background) {
    <img alt='' src='{{event.sport.id | sportImage: true }}'
         class='scp-sports-event-card__background {{event.sport.id | sportId}}' />
  }
  <div class='scp-sports-event-card__info'
       [class.background-sport]='background || !sport'
       [class.completed]='(event.status === "Ended" || event.status === "Finished")'>
    @if (!background && sport) {
      <img class='scp-sports-event-card__sport'
           loading='lazy'
           alt=''
           src='{{event.sport.id | sportImage}}' height='14' width='14' />
    }
    @if (event.live && (event.status !== "Ended" && event.status !== "Finished")) {
      <span class='scp-sports-event-card__live'>{{ 'LIVE' | translate }}</span>
    }
    @if (!event.live || (event.status === "Ended" || event.status === "Finished")) {
      <span style='display: initial'>
        <span class='scp-sports-event-card__info__date' #countdownLabel></span>
        <span class='scp-sports-event-card__info__time' #countdown></span>
      </span>
    }

    @if (!event.live && event.status !== "Ended" && event.status !== "Finished" && event.matchProperties?.aggregatehomescore && event.matchProperties?.aggregateawayscore) {
      <div class='scp-sports-event-card__info__aggr_score'>
        AGGR. {{ event.matchProperties.aggregateawayscore }}–{{ event.matchProperties.aggregatehomescore }}
      </div>
    } @else {
      <div></div>
    }

    @if (event.live) {
      <span style='display: initial'>
        <span class='scp-sports-event-card__info__date live'>{{ event | period }}</span>
      </span>
    }
    <div class='scp-sports-event-card__info__options'>
      @if (tracker) {
        @if (event.video) {
          <img class='scp-sports-event-card__info__options__video' loading='lazy' alt=''
               src='/assets/images/sports/video-orange.svg' height='11' width='13' />
        }
        @if (event.tracker) {
          <img class='scp-sports-event-card__info__options__tracker' loading='lazy' alt=''
               src='/assets/images/sports/tracker.svg' height='11' width='15' />
        }
        @if (event.outcomesCount > 0 && (event.tracker || event.video)) {
          <div class='scp-sports-event-card__info__options__divider'></div>
        }
      } @else if (!event.live && event.video) {
        <img class='scp-sports-event-card__info__options__video' loading='lazy' alt=''
             src='/assets/images/sports/video-orange.svg' height='11' width='13' />
        @if (markets && event.outcomesCount > 0) {
          <div class='scp-sports-event-card__info__options__divider'></div>
        }
      }
      @if (markets && event.outcomesCount > 0) {
        <span class='outcomes'>+{{ event.outcomesCount }}</span>
      }
    </div>
  </div>
  @if (event.season) {
    <meta itemprop='name' content='{{event.season.name}}' />
    <meta itemprop='description' content='Bets on {{event.season.name}} event at {{site}} online'>
    <div class='scp-sports-event-card__participant-season-logo'>
      <img alt='' [src]='event.season.id | competitorImage' [title]='event.season.name' loading='lazy'
           scpImageFallback='{{ event.sport | defaultSeasonImage }}' />
    </div>
    <div class='scp-sports-event-card__participant-season'><span>{{ event.season.name }}</span></div>
  } @else {
    <meta itemprop='name' content='{{event.homeTeam.name}} - {{event.awayTeam.name}}' />
    <meta itemprop='description'
          content='Bets on {{event.homeTeam.name}} - {{event.awayTeam.name}} event at {{site}} online'>
    <div class='scp-sports-event-card__participant-home-logo {{event.sport.id | sportId}}'>
      <img alt='' [src]='event.homeTeam.id | competitorImage' [title]='event.homeTeam.name' loading='lazy'
           scpImageFallback='{{ event.sport | defaultCompetitorImage }}' />
    </div>
    <div class='scp-sports-event-card__participant-home'
         itemprop='homeTeam'
         itemscope
         itemtype='https://schema.org/SportsTeam'>
      <span itemprop='name'>{{ event.homeTeam.name }}</span>
      @if (event.homeRC > 0) {
        <span class='scp-sports-event-card__red-card'
              [innerText]='event.homeRC > 1 ? event.homeRC : ""'>
        </span>
      }
    </div>
    <div class='scp-sports-event-card__participant-away-logo {{event.sport.id | sportId}}'>
      <img alt='' [src]='event.awayTeam.id | competitorImage' [title]='event.awayTeam.name' loading='lazy'
           scpImageFallback='{{ event.sport | defaultCompetitorImage }}' />
    </div>
    <div class='scp-sports-event-card__participant-away'
         itemprop='awayTeam'
         itemscope
         itemtype='https://schema.org/SportsTeam'>
      <span itemprop='name'>{{ event.awayTeam.name }}</span>
      @if (event.awayRC > 0) {
        <span class='scp-sports-event-card__red-card'
              [innerText]='event.awayRC > 1 ? event.awayRC : ""'>
        </span>
      }
    </div>
  }
  <div class='scp-sports-event-card__results'>
    @if (event.live) {
      @switch (event.sport.id) {
        @case ("sr:sport:1") {
          <scp-sports-event-soccer-live-results [event]='event'
                                                [scores]='event.periodScores || []'
                                                [matchProperties]='event.matchProperties  || {}'
                                                [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-soccer-live-results>
        }
        @case ("sr:sport:2") {
          <scp-sports-event-basketball-live-results [event]='event'
                                                    [scores]='event.periodScores || []'
                                                    [matchProperties]='event.matchProperties  || {}'
                                                    [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-basketball-live-results>
        }
        @case ("sr:sport:3") {
          <scp-sports-event-baseball-live-results [event]='event'
                                                  [scores]='event.periodScores || []'
                                                  [matchProperties]='event.matchProperties  || {}'
                                                  [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-baseball-live-results>
        }
        @case ("sr:sport:4") {
          <scp-sports-event-ice-hockey-live-results [event]='event'
                                                    [scores]='event.periodScores || []'
                                                    [matchProperties]='event.matchProperties || {}'
                                                    [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-ice-hockey-live-results>
        }
        @case ("sr:sport:5") {
          <scp-sports-event-tennis-live-results [event]='event'
                                                [scores]='event.periodScores || []'
                                                [matchProperties]='event.matchProperties  || {}'
                                                [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-tennis-live-results>
        }
        @case ("sr:sport:6") {
          <scp-sports-event-handball-live-results [event]='event'
                                                  [scores]='event.periodScores || []'
                                                  [matchProperties]='event.matchProperties  || {}'
                                                  [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-handball-live-results>
        }
        @case ("sr:sport:9") {
          <scp-sports-event-golf-live-results [event]='event'
                                              [scores]='event.periodScores || []'
                                              [matchProperties]='event.matchProperties  || {}'
                                              [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-golf-live-results>
        }
        @case ("sr:sport:12") {
          <scp-sports-event-rugby-live-results [event]='event'
                                               [scores]='event.periodScores || []'
                                               [matchProperties]='event.matchProperties  || {}'
                                               [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-rugby-live-results>
        }
        @case ("sr:sport:13") {
          <scp-sports-event-aussie-rules-live-results [event]='event'
                                                      [scores]='event.periodScores || []'
                                                      [matchProperties]='event.matchProperties  || {}'
                                                      [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-aussie-rules-live-results>
        }
        @case ("sr:sport:16") {
          <scp-sports-event-american-football-live-results [event]='event'
                                                           [scores]='event.periodScores || []'
                                                           [matchProperties]='event.matchProperties  || {}'
                                                           [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-american-football-live-results>
        }
        @case ("sr:sport:19") {
          <scp-sports-event-snooker-live-results [event]='event'
                                                 [scores]='event.periodScores || []'
                                                 [matchProperties]='event.matchProperties  || {}'
                                                 [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-snooker-live-results>
        }
        @case ("sr:sport:20") {
          <scp-sports-event-table-tennis-live-results [event]='event'
                                                      [scores]='event.periodScores || []'
                                                      [matchProperties]='event.matchProperties  || {}'
                                                      [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-table-tennis-live-results>
        }
        @case ("sr:sport:21") {
          <scp-sports-event-cricket-live-results [event]='event'
                                                 [scores]='event.periodScores || []'
                                                 [matchProperties]='event.matchProperties  || {}'
                                                 [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-cricket-live-results>
        }
        @case ("sr:sport:22") {
          <scp-sports-event-darts-live-results [event]='event'
                                               [scores]='event.periodScores || []'
                                               [matchProperties]='event.matchProperties  || {}'
                                               [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-darts-live-results>
        }
        @case ("sr:sport:23") {
          <scp-sports-event-volleyball-live-results [event]='event'
                                                    [scores]='event.periodScores || []'
                                                    [matchProperties]='event.matchProperties  || {}'
                                                    [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-volleyball-live-results>
        }
        @case ("sr:sport:24") {
          <scp-sports-event-field-hockey-live-results [event]='event'
                                                      [scores]='event.periodScores || []'
                                                      [matchProperties]='event.matchProperties  || {}'
                                                      [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-field-hockey-live-results>
        }
        @case ("sr:sport:26") {
          <scp-sports-event-waterpolo-live-results [event]='event'
                                                   [scores]='event.periodScores || []'
                                                   [matchProperties]='event.matchProperties || {}'
                                                   [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-waterpolo-live-results>
        }
        @case ("sr:sport:29") {
          <scp-sports-event-futsal-live-results [event]='event'
                                                [scores]='event.periodScores || []'
                                                [matchProperties]='event.matchProperties  || {}'
                                                [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-futsal-live-results>
        }
        @case ("sr:sport:31") {
          <scp-sports-event-badminton-live-results [event]='event'
                                                   [scores]='event.periodScores || []'
                                                   [matchProperties]='event.matchProperties  || {}'
                                                   [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-badminton-live-results>
        }
        @case ("sr:sport:32") {
          <scp-sports-event-bowls-live-results [event]='event'
                                               [scores]='event.periodScores || []'
                                               [matchProperties]='event.matchProperties  || {}'
                                               [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-bowls-live-results>
        }
        @case ("sr:sport:34") {
          <scp-sports-event-beach-volleyball-live-results [event]='event'
                                                          [scores]='event.periodScores || []'
                                                          [matchProperties]='event.matchProperties  || {}'
                                                          [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-beach-volleyball-live-results>
        }
        @case ("sr:sport:37") {
          <scp-sports-event-squash-live-results [event]='event'
                                                [scores]='event.periodScores || []'
                                                [matchProperties]='event.matchProperties  || {}'
                                                [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-squash-live-results>
        }
        @case ("sr:sport:40") {
          <scp-sports-event-formula-1-live-results [event]='event'
                                                   [scores]='event.periodScores || []'
                                                   [matchProperties]='event.matchProperties  || {}'
                                                   [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-formula-1-live-results>
        }
        @case ("sr:sport:43") {
          <scp-sports-event-alpine-skiing-live-results [event]='event'
                                                       [scores]='event.periodScores || []'
                                                       [matchProperties]='event.matchProperties  || {}'
                                                       [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-alpine-skiing-live-results>
        }
        @case ("sr:sport:44") {
          <scp-sports-event-biathlon-live-results [event]='event'
                                                  [scores]='event.periodScores || []'
                                                  [matchProperties]='event.matchProperties  || {}'
                                                  [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-biathlon-live-results>
        }
        @case ("sr:sport:48") {
          <scp-sports-event-ski-jumping-live-results [event]='event'
                                                     [scores]='event.periodScores || []'
                                                     [matchProperties]='event.matchProperties  || {}'
                                                     [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-ski-jumping-live-results>
        }
        @case ("sr:sport:71") {
          <scp-sports-event-padel-live-results [event]='event'
                                               [scores]='event.periodScores || []'
                                               [matchProperties]='event.matchProperties  || {}'
                                               [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-padel-live-results>
        }
        @case ("sr:sport:109") {
          <scp-sports-event-counter-strike-live-results [event]='event'
                                                        [scores]='event.periodScores || []'
                                                        [matchProperties]='event.matchProperties  || {}'
                                                        [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-counter-strike-live-results>
        }
        @case ("sr:sport:110") {
          <scp-sports-event-league-of-legends-live-results [event]='event'
                                                           [scores]='event.periodScores || []'
                                                           [matchProperties]='event.matchProperties  || {}'
                                                           [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-league-of-legends-live-results>
        }
        @case ("sr:sport:111") {
          <scp-sports-event-dota-live-results [event]='event'
                                              [scores]='event.periodScores || []'
                                              [matchProperties]='event.matchProperties  || {}'
                                              [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-dota-live-results>
        }
        @case ("sr:sport:117") {
          <scp-sports-event-mma-live-results [event]='event'
                                             [scores]='event.periodScores || []'
                                             [matchProperties]='event.matchProperties  || {}'
                                             [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-mma-live-results>
        }
        @case ("sr:sport:142") {
          <scp-sports-event-formula-e-live-results [event]='event'
                                                   [scores]='event.periodScores || []'
                                                   [matchProperties]='event.matchProperties  || {}'
                                                   [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-formula-e-live-results>
        }
        @case ("sr:sport:155") {
          <scp-sports-event-basketball-3x3-live-results [event]='event'
                                                        [scores]='event.periodScores || []'
                                                        [matchProperties]='event.matchProperties || {}'
                                                        [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-basketball-3x3-live-results>
        }
        @case ("sr:sport:190") {
          <scp-sports-event-moto-gp-live-results [event]='event'
                                                 [scores]='event.periodScores || []'
                                                 [matchProperties]='event.matchProperties || {}'
                                                 [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-moto-gp-live-results>
        }
        @case ("sr:sport:190") {
          <scp-sports-event-nascar-live-results [event]='event'
                                                [scores]='event.periodScores || []'
                                                [matchProperties]='event.matchProperties || {}'
                                                [border]='(markets && event.outcomesCount > 0) || event.tracker'>
          </scp-sports-event-nascar-live-results>
        }
      }
    }
  </div>
</div>
