import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-darts-live-results',
  templateUrl: './event-darts-live-results.component.html',
  styleUrls: ['./event-darts-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDartsLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '93', '94', '95', '96', '97', '98', '80', '90'];
  private static readonly MATCH_BREAKS_CODES = ['30'];

  protected pLL: EventPeriodScore;

  protected hC: number;
  protected aC: number;

  protected hT: number;
  protected aT: number;

  protected s: string;

  protected getEndedCodes(): string[] {
    return EventDartsLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    this.s = EventDartsLiveResultsComponent.MATCH_BREAKS_CODES.includes(this.matchProperties.matchstatusid)
      ? null
      : this.matchProperties.visit;
    this.pLL = this.scores[this.scores.length - 1];
    this.hC = Number(this.matchProperties.homelegscore);
    this.aC = Number(this.matchProperties.awaylegscore);
    this.hT = Number(this.matchProperties.homescore);
    this.aT = Number(this.matchProperties.awayscore);
  }

}
