<div class='scp-sports-event-top-markets grid-{{type}} {{event.sport.id | sportId}}'
     [class.season]='event.season'
     [class.mini]='mini'>
  @if (event.season) {
    <div class='scp-sports-event-top-markets__market o more visible'>
      <div class='scp-sports-event-top-markets__market__outcomes'>
        <a class='scp-sports-event-top-markets__market__outcomes__outcome fill'
           [routerLink]="'/sports/events/' + event.slug"
           [state]='{events: true, tournament: event.tournament.id}'
           (click)='googleTagManagerService.selectSportItem(event, source)'>
          <span>{{ 'More markets' | translate }}</span>
        </a>
      </div>
    </div>
  } @else {
    <div class='scp-sports-event-top-markets__content'>
      @for (item of items; track item.layout.market.id) {
        <div class='scp-sports-event-top-markets__market market-type-{{item.layout.market.id}} t'
             style='grid-column: span {{item.layout.size || item.layout.outcomes.length}}; grid-row: 1'>
          <div class='scp-sports-event-top-markets__market__name'>{{ item.layout.market.name }}</div>
        </div>
      }
      @for (item of items; track item.layout.market.id) {
        <div
          class='scp-sports-event-top-markets__market o market-type-{{item.layout.market.id}}'
          style='grid-column: span {{item.layout.size || item.layout.outcomes.length}}; grid-row: 2'>
          <div
            class='scp-sports-event-top-markets__market__outcomes grid-{{item.layout.outcomes.length}}'>
            @for (outcome of item.layout.outcomes; track outcome.id) {
              <button class='scp-sports-event-top-markets__market__outcomes__outcome {{outcome.selector}}'
                      (click)='addToCard(item, outcome.id, $event)'>
                <span class='name'>{{ outcome.name }}</span>
                <span class='odds'></span>
              </button>
            }
          </div>
        </div>
      }
      <div class='scp-sports-event-top-markets__market o more'>
        <div class='scp-sports-event-top-markets__market__outcomes'>
          <a class='scp-sports-event-top-markets__market__outcomes__outcome fill'
             [routerLink]="'/sports/events/' + event.slug"
             [state]='{events: true, tournament: event.tournament.id}'
             (click)='googleTagManagerService.selectSportItem(event, source)'>
            <span>{{ 'More markets' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  }
</div>
