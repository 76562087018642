import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

// TODO (padel): add correct markets and outcomes
export const MARKETS_PADEL: [string, MarketLayout[]] = [
  'sr:sport:71',
  [
    {
      market: { id: 'm1', name: '' },
      outcomes: [
        { id: 'o1', selector: 'outcome-type-o1', name: '' },
        { id: 'o2', selector: 'outcome-type-o2', name: '' }
      ]
    },
    {
      market: { id: 'm2', name: '' },
      outcomes: [
        { id: 'o1', selector: 'outcome-type-o1', name: '' },
        { id: 'o2', selector: 'outcome-type-o2', name: '' }
      ]
    },
    {
      market: { id: 'm3', name: '' },
      outcomes: [
        { id: 'o1', selector: 'outcome-type-o1', name: '' },
        { id: 'o2', selector: 'outcome-type-o2', name: '' }
      ]
    }
  ]
];
