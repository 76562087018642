@if (scores?.length) {
  <div class='scp-event-squash-live-results'>
    @if (ended) {
      <div class='results-grid columns-1' [class.without-border]='!border'>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    } @else {
      <div class='results-grid columns-3 float' [class.without-border]='!border'>
        <span></span>
        <span class='header'>{{ pLS.periodNumber }}G</span>
        <span class='header'>M</span>
        <span [class.current-server]='s === "1"'></span>
        <span [class.highlighted-cell]='pLS.homeScore > pLS.awayScore' class='highlighted-color'>{{ pLS.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.current-server]='s === "2"'></span>
        <span [class.highlighted-cell]='pLS.homeScore < pLS.awayScore' class='highlighted-color'>{{ pLS.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    }
  </div>
}
