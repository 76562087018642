<div class='scp-event-ice-hockey-live-results'>
  @switch (scores.length) {
    @case (1) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>1P</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p1st.homeScore > p1st.awayScore'>{{ p1st.homeScore }}</span>
        <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
        <span [class.highlighted-cell]='p1st.homeScore < p1st.awayScore'>{{ p1st.awayScore }}</span>
        <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
      </div>
    }
    @case (2) {
      <div class='results-grid columns-3' [class.without-border]='!border'>
        <span class='header'>1P</span>
        <span class='header'>2P</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p1st.homeScore > p1st.awayScore'>{{ p1st.homeScore }}</span>
        <span [class.highlighted-cell]='p2nd.homeScore > p2nd.awayScore'>{{ p2nd.homeScore }}</span>
        <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
        <span [class.highlighted-cell]='p1st.homeScore < p1st.awayScore'>{{ p1st.awayScore }}</span>
        <span [class.highlighted-cell]='p2nd.homeScore < p2nd.awayScore'>{{ p2nd.awayScore }}</span>
        <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
      </div>
    }
    @case (3) {
      <div class='results-grid columns-3' [class.without-border]='!border'>
        <span class='header'>2P</span>
        <span class='header'>3P</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='p2nd.homeScore > p2nd.awayScore'>{{ p2nd.homeScore }}</span>
        <span [class.highlighted-cell]='p3rd.homeScore > p3rd.awayScore'>{{ p3rd.homeScore }}</span>
        <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
        <span [class.highlighted-cell]='p2nd.homeScore < p2nd.awayScore'>{{ p2nd.awayScore }}</span>
        <span [class.highlighted-cell]='p3rd.homeScore < p3rd.awayScore'>{{ p3rd.awayScore }}</span>
        <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
      </div>
    }
    @case (4) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>OT</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='pOT.homeScore > pOT.awayScore'>{{ pOT.homeScore }}</span>
        <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
        <span [class.highlighted-cell]='pOT.homeScore < pOT.awayScore'>{{ pOT.awayScore }}</span>
        <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
      </div>
    }
    @case (5) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>P</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='pP.homeScore > pP.awayScore'>{{ pP.homeScore }}</span>
        <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
        <span [class.highlighted-cell]='pP.homeScore < pP.awayScore'>{{ pP.awayScore }}</span>
        <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
      </div>
    }
  }
</div>
