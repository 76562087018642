import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-baseball-live-results',
  templateUrl: './event-baseball-live-results.component.html',
  styleUrls: ['./event-baseball-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBaseballLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '110'];
  protected hT: number;
  protected aT: number;

  protected p1: EventPeriodScore;
  protected p2: EventPeriodScore;

  protected s: string;

  protected getEndedCodes(): string[] {
    return EventBaseballLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    const status = Number(this.matchProperties.matchstatusid);
    if (status >= 401 && status <= 419) {
      this.s = status % 2 === 0 ? '1' : '2';
    } else {
      this.s = null;
    }
    this.p1 = this.scores[this.scores.length - 2];
    this.p2 = this.scores[this.scores.length - 1];
    this.hT = Number(this.matchProperties.homescore);
    this.aT = Number(this.matchProperties.awayscore);
  }

}
