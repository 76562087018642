<div class='scp-sports-tournament-icon' [class.title]='title'>
  <picture>
    <img [src]='tournament.id | tournamentImage' alt=''
         [title]='tournament.name'
         height='18' width='25'
         style='opacity: 0'
         scpImageFallback='{{ sport | defaultTournamentImage }}'
         loading='lazy' />
  </picture>
  @if (title) {
    <div>{{ category.name + ". " + tournament.name }}</div>
  }
</div>
