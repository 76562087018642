<div class='scp-event-cricket-live-results'>
  @if (ended) {
    <div class='results-grid columns-1' [class.without-border]='!border'>
      <span class='header'>M</span>
      <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
      <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
    </div>
  } @else {
    <div class='results-grid columns-3' [class.without-border]='!border'>
      <span class='header'></span>
      <span class='header'>{{ period }}</span>
      <span class='header'>M</span>
      <span [class.current-server]='serve === "2"' [class.bat]='serve === "1"'></span>
      <span [class.highlighted-cell]='hIS> aIS' style='white-space: nowrap;'>{{ hI }}</span>
      <span [class.highlighted-cell]='hTT > aTT' class='highlighted-color'>{{ hTT }}</span>
      <span [class.current-server]='serve === "1"' [class.bat]='serve === "2"'></span>
      <span [class.highlighted-cell]='hIS < aIS' style='white-space: nowrap;'>{{ aI }}</span>
      <span [class.highlighted-cell]='hTT < aTT' class='highlighted-color'>{{ aTT }}</span>
    </div>
  }
</div>
