import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-aussie-rules-live-results',
  templateUrl: './event-aussie-rules-live-results.component.html',
  styleUrls: ['./event-aussie-rules-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAussieRulesLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '440', '444'];

  protected p1st: EventPeriodScore;
  protected p2nd: EventPeriodScore;
  protected p3rd: EventPeriodScore;
  protected p4th: EventPeriodScore;
  protected p1stOT: EventPeriodScore;
  protected p2ndOT: EventPeriodScore;
  protected pSD: EventPeriodScore;
  protected hTT: number;
  protected aTT: number;

  protected getEndedCodes(): string[] {
    return EventAussieRulesLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    this.p1st = this.scores[0];
    this.p2nd = this.scores[1];
    this.p3rd = this.scores[2];
    this.p4th = this.scores[3];
    this.p1stOT = this.scores[4];
    this.p2ndOT = this.scores[5];
    this.pSD = this.scores[6];
    this.hTT = Number(this.matchProperties.homescore);
    this.aTT = Number(this.matchProperties.awayscore);
  }

}
