import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-cricket-live-results',
  templateUrl: './event-cricket-live-results.component.html',
  styleUrls: ['./event-cricket-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCricketLiveResultsComponent extends EventLiveResultsComponent {

  protected static readonly FIRST_TEAM_SERVE = ['501', '503', '506'];
  protected static readonly SECOND_TEAM_SERVE = ['502', '504', '507'];

  protected static readonly SECOND_INNINGS = [503, 504];
  protected static readonly SUPER_OVER = [506, 507];

  protected hTT: number;
  protected aTT: number;

  protected hI: string;
  protected aI: string;

  protected hIS: number;
  protected aIS: number;

  protected serve: string;

  protected period: string;

  public constructor() {
    super();
  }

  protected getEndedCodes(): string[] {
    return ['100', '508'];
  }

  protected updateScore() {
    const p = this.scores[this.scores.length - 1];
    this.hTT = this.scores.reduce((t: number, c: EventPeriodScore) => t + c.homeScore || 0, 0);
    this.aTT = this.scores.reduce((t: number, c: EventPeriodScore) => t + c.awayScore || 0, 0);

    if (!this.ended) {
      if (EventCricketLiveResultsComponent.FIRST_TEAM_SERVE.includes(this.matchProperties.matchstatusid)) {
        this.serve = '1';
      } else if (EventCricketLiveResultsComponent.SECOND_TEAM_SERVE.includes(this.matchProperties.matchstatusid)) {
        this.serve = '2';
      } else {
        this.serve = '';
      }
      this.period = this.getInnings(p?.matchStatusCode);
      this.hIS = this.scores
        .filter(t => this.period === this.getInnings(t.matchStatusCode) &&
          EventCricketLiveResultsComponent.FIRST_TEAM_SERVE.includes(t.matchStatusCode.toString()))
        .reduce((t: number, c: EventPeriodScore) => t + (c.homeScore || 0), 0);
      this.aIS = this.scores
        .filter(t => this.period === this.getInnings(t.matchStatusCode) &&
          EventCricketLiveResultsComponent.SECOND_TEAM_SERVE.includes(t.matchStatusCode.toString()))
        .reduce((t: number, c: EventPeriodScore) => t + (c.awayScore || 0), 0);
      this.hI = `${this.hIS}/${this.matchProperties.homedismissals || 0}`;
      this.aI = `${this.aIS}/${this.matchProperties.awaydismissals || 0}`;
    } else {
      this.period = '';
    }
  }

  private getInnings(status: number): string {
    if (EventCricketLiveResultsComponent.SUPER_OVER.includes(status)) {
      return 'SO';
    } else if (EventCricketLiveResultsComponent.SECOND_INNINGS.includes(status)) {
      return '2I';
    }
    return '1I';
  }

}
