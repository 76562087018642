import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Event, EventMarket, EventOutcome } from './../../dto';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';
import { TOP_MARKETS } from '@scpc/modules/sports/components/event-top-markets/sports';
import { SportIdPipe } from '@scpc/modules/sports/pipes/sport-id';
import { SportsCartService } from '@scpc/modules/cart/services/sports-cart.service';
import { SportCartSelectionId } from '@scpc/modules/cart/model';
import { CommonCartService } from '@scpc/modules/cart/services/common-cart.service';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'scp-sports-event-top-markets',
  templateUrl: './event-top-markets.component.html',
  styleUrls: ['./event-top-markets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    RouterLink,
    SportIdPipe,
  ],
})
export class EventTopMarketsComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public event: Event;

  @Input()
  public mini: boolean;

  @Input()
  public source: string;

  protected type: string;
  protected items: { market?: EventMarket, layout: MarketLayout, old?: EventMarket, changed: boolean }[] = [];
  private init: boolean = false;
  private creating: SportCartSelectionId[] = [];
  private deleting: SportCartSelectionId[] = [];
  private destroyRef: DestroyRef = inject(DestroyRef);
  private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(protected readonly googleTagManagerService: GoogleTagManagerService,
              private readonly commonCartService: CommonCartService,
              private readonly sportsCartService: SportsCartService,
              private readonly elementRef: ElementRef<HTMLElement>,
              private readonly renderer: Renderer2,
              private readonly zone: NgZone) {
  }

  public ngOnInit(): void {
    this.updateLayout();
    this.sportsCartService.items()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ids: SportCartSelectionId[]): void => {
        for (const id of ids) {
          if (id.eventId === this.event.id) {
            this.renderMarkets();
            break;
          }
        }
      });
    this.sportsCartService.deletingItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ids: SportCartSelectionId[]): void => {
        const items: SportCartSelectionId[] = [];
        for (const id of ids) {
          if (id.eventId === this.event.id) {
            items.push(id);
          }
        }
        if (items.length !== this.deleting.length) {
          this.deleting = items;
          this.renderMarkets();
        }
      });
    this.sportsCartService.creatingItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ids: SportCartSelectionId[]): void => {
        const items: SportCartSelectionId[] = [];
        for (const id of ids) {
          if (id.eventId === this.event.id) {
            items.push(id);
          }
        }
        if (items.length !== this.creating.length) {
          this.creating = items;
          this.renderMarkets();
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('event' in changes) {
      if (!this.event.season && changes.event.previousValue) {
        if ((changes.event.currentValue as Event)?.matchProperties?.matchstatusid !== (changes.event.previousValue as Event)?.matchProperties?.matchstatusid) {
          this.updateLayout(true);
        }
        this.renderMarkets();
      }
    }
  }

  public ngAfterViewInit(): void {
    this.init = !!this.items;
    this.renderMarkets();
  }

  protected async addToCard(item: {
    market?: EventMarket,
    layout: MarketLayout,
    old?: EventMarket,
    changed: boolean
  }, outcomeId: string, $event: MouseEvent): Promise<void> {
    if (await this.commonCartService.isBetAllowed('SPORT')) {
      $event.preventDefault();
      $event.stopPropagation();
      await this.sportsCartService.addOrRemoveSelection(this.event, item.market, outcomeId, this.source);
    }
  }

  private updateLayout(changeDetection: boolean = true): void {
    if (!this.event.season) {
      const markets: MarketLayout[] = TOP_MARKETS.get(this.event.sport.id) || [];
      let current: MarketLayout[];

      if (this.event.matchProperties) {
        current = markets.filter((m: MarketLayout): boolean => m.status?.includes(this.event.matchProperties.matchstatusid));
      }
      if (!current?.length) {
        current = markets.filter((m: MarketLayout): boolean => !m.status?.length);
      }

      this.items = current.map(item => ({
        layout: item,
        market: undefined,
        changed: false,
      }));

      if (this.items.length) {
        this.type = `${this.items[0].layout.size || this.items[0].layout.outcomes.length}-${this.items[1].layout.size || this.items[1].layout.outcomes.length}-${this.items[2].layout.size || this.items[2].layout.outcomes.length}`;
      }
      if (changeDetection) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private renderMarkets(cleanOdds: boolean = false): void {
    if (!this.event.season && this.init) {
      this.zone.runOutsideAngular(() => {
        for (const item of this.items) {
          const old = item.market;
          item.market = this.event.markets.find(market => market.id === item.layout.market.id);
          /* istanbul ignore if */
          if (!item.market && item.layout.market.alternativeId) {
            item.market = this.event.markets.find(market => market.id === item.layout.market.alternativeId);
          }
          if (old?.marketUniqId === item.market?.marketUniqId) {
            item.old = old;
            item.changed = false;
          } else {
            item.changed = !!old;
          }
          const marketSelector: string = '.market-type-' + item.layout.market.id;
          /* istanbul ignore if */
          if (!item.layout.market.name) {
            const textContent: string = item.market?.name || '';
            const element: Element = this.elementRef.nativeElement.querySelector(marketSelector + ' .scp-sports-event-top-markets__market__name');
            if (element.textContent !== textContent) {
              this.renderer.setProperty(element, 'textContent', item.market?.name || '');
            }
          }
          const marketActive: boolean = item.market?.status === 'Active';
          const marketNotPartial: boolean = item.layout.outcomes.length === item.market?.outcomes.length;
          this.updateMarketAvailability(marketSelector, marketActive, marketNotPartial);
          for (const out of item.layout.outcomes) {
            const outcome = item.market?.outcomes.find(o => o.id === out.id);
            const oldOutcome = outcome && !cleanOdds ? item.old?.outcomes.find(o => o.id === outcome.id) : null;
            const outcomeSelector = marketSelector + ' .outcome-type-' + out.id;
            if (!out.name) {
              this.updateOutcome(outcomeSelector, item.changed, outcome);
            }
            this.updateOdds(outcomeSelector, item.market, outcome, !item.changed && marketActive ? oldOutcome : null);
          }
        }
      });
    }
  }

  private updateMarketAvailability(selector: string, available: boolean, partial: boolean): void {
    const element: NodeListOf<Element> = this.elementRef.nativeElement.querySelectorAll(selector);
    if (available) {
      element.forEach((e: Element) => this.renderer.removeClass(e, 'not-available'));
    } else {
      element.forEach((e: Element) => this.renderer.addClass(e, 'not-available'));
    }
    if (partial) {
      element.forEach((e: Element) => this.renderer.removeClass(e, 'partial'));
    } else {
      element.forEach((e: Element) => this.renderer.addClass(e, 'partial'));
    }
  }

  private updateOdds(selector: string, market?: EventMarket, outcome?: EventOutcome, oldOutcome?: EventOutcome): void {
    const buttonElement: HTMLButtonElement = this.elementRef.nativeElement.querySelector(selector);
    this.renderer.setProperty(buttonElement, 'disabled', !outcome || !outcome.active);
    const textElement: HTMLSpanElement = buttonElement.querySelector('span:nth-child(2)');
    this.renderer.setProperty(textElement, 'textContent', (outcome?.odds || 0).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
    }));

    if (outcome?.odds && oldOutcome && outcome.odds > oldOutcome.odds && !outcome.update) {
      this.renderer.removeClass(buttonElement, 'up');
      this.renderer.removeClass(buttonElement, 'down');
      this.renderer.addClass(buttonElement, 'up');
      outcome.update = Date.now();
    } else if (outcome?.odds && oldOutcome && outcome.odds < oldOutcome.odds && !outcome.update) {
      this.renderer.removeClass(buttonElement, 'up');
      this.renderer.removeClass(buttonElement, 'down');
      this.renderer.addClass(buttonElement, 'down');
      outcome.update = Date.now();
    } else if (!outcome || outcome.update) {
      this.renderer.removeClass(buttonElement, 'up');
      this.renderer.removeClass(buttonElement, 'down');
    }

    if (market && outcome) {
      const selectionId: SportCartSelectionId = {
        eventId: this.event.id,
        marketUniqId: market.marketUniqId,
        outcomeId: outcome.id,
      };

      if (this.sportsCartService.hasSelection(selectionId)) {
        this.renderer.addClass(buttonElement, 'selected');
      } else {
        this.renderer.removeClass(buttonElement, 'selected');
      }
      if (market && outcome && (this.sportsCartService.isDeleting(selectionId) || this.sportsCartService.isCreating(selectionId))) {
        this.renderer.addClass(buttonElement, 'selected');
        this.renderer.addClass(buttonElement, 'processing');
      } else {
        this.renderer.removeClass(buttonElement, 'processing');
      }
    } else {
      this.renderer.removeClass(buttonElement, 'selected');
      this.renderer.removeClass(buttonElement, 'processing');
    }
  }

  private updateOutcome(selector: string, changed: boolean, outcome?: EventOutcome) {
    const element: HTMLButtonElement = this.elementRef.nativeElement.querySelector(selector + ' span:nth-child(1)');
    this.renderer.setProperty(element, 'textContent', outcome?.name || '–');
    if (changed && outcome) {
      this.renderer.addClass(element, 'changed');
    } else {
      this.renderer.removeClass(element, 'changed');
    }
  }

}
