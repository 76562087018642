import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_HANDBALL: [string, MarketLayout[]] = [
  'sr:sport:6',
  [
    {
      market: { id: '1', name: '1x2' },
      outcomes: [
        { id: '1', selector: 'outcome-type-1', name: '1' },
        { id: '2', selector: 'outcome-type-2', name: 'X' },
        { id: '3', selector: 'outcome-type-3', name: '2' }
      ]
    },
    {
      market: { id: '18', name: 'Total' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: '16', name: 'Handicap' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' }
      ]
    }
  ]
];
