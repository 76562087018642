import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';

@Component({
  selector: 'scp-sports-event-formula-1-live-results',
  templateUrl: './event-formula-1-live-results.component.html',
  styleUrls: ['./event-formula-1-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormula1LiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = [];

  protected getEndedCodes(): string[] {
    return EventFormula1LiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    // TODO (formula 1): show results
  }

}
