import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_AMERICAN_FOOTBALL: [string, MarketLayout[]] = [
  'sr:sport:16',
  [
    {
      market: { id: '219', name: 'Winner (incl. overtime)' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '' },
        { id: '5', selector: 'outcome-type-5', name: '' }
      ]
    },
    {
      market: { id: '225', name: 'Total (incl. overtime)' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' }
      ]
    },
    {
      market: { id: '223', name: 'Handicap (incl. overtime)' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' }
      ]
    }
  ]
];
