import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-bowls-live-results',
  templateUrl: './event-bowls-live-results.component.html',
  styleUrls: ['./event-bowls-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBowlsLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '93', '94', '95', '96'];
  private static readonly MATCH_BREAKS_CODES = ['301', '302', '303', '304'];

  protected pLS: EventPeriodScore;

  protected hT: number;
  protected aT: number;

  protected s: string;

  public constructor() {
    super();
  }

  protected getEndedCodes(): string[] {
    return EventBowlsLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    this.s = EventBowlsLiveResultsComponent.MATCH_BREAKS_CODES.includes(this.matchProperties.matchstatusid)
      ? null
      : this.matchProperties.delivery;
    this.pLS = this.scores[this.scores.length - 1];
    this.hT = Number(this.matchProperties.homescore);
    this.aT = Number(this.matchProperties.awayscore);
  }

}
