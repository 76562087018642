@if (scores?.length) {
  <div class='scp-event-padel-live-results'>
    @if (ended) {
      <div class='results-grid columns-1' [class.without-border]='!border'>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    } @else {
      <div class='results-grid columns-4 float' [class.without-border]='!border'>
        <span></span>
        <span class='header'>G</span>
        <span class='header'>{{ pLS.periodNumber ? pLS.periodNumber + "S" : "" }}</span>
        <span class='header'>M</span>
        <span [class.current-server]='s === "1"'></span>
        <span [class.highlighted-cell]='hTG > aTG' class='highlighted-color'>{{ hTG > 40 ? 'Adv.' : hTG }}</span>
        <span [class.highlighted-cell]='pLS.homeScore > pLS.awayScore'>{{ pLS.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.current-server]='s === "2"'></span>
        <span [class.highlighted-cell]='hTG < aTG' class='highlighted-color'>{{ aTG > 40 ? 'Adv.' : aTG }}</span>
        <span [class.highlighted-cell]='pLS.homeScore < pLS.awayScore'>{{ pLS.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    }
  </div>
}
