import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-handball-live-results',
  templateUrl: './event-handball-live-results.component.html',
  styleUrls: ['./event-handball-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventHandballLiveResultsComponent extends EventLiveResultsComponent {
  private static readonly MATCH_ENDED_CODES = ['100', '110', '120'];

  protected p1st: EventPeriodScore;
  protected p2nd: EventPeriodScore;
  protected pOT: EventPeriodScore;
  protected pP: EventPeriodScore;

  protected hTT: number;
  protected aTT: number;

  protected getEndedCodes(): string[] {
    return EventHandballLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    this.p1st = this.scores[0];
    this.p2nd = this.scores[1];
    this.pOT = this.scores[2];
    this.pP = this.scores[3];
    this.hTT = this.scores.filter(s => s.periodNumber <= 3).reduce((t: number, c: EventPeriodScore) => t + c.homeScore, 0);
    this.aTT = this.scores.filter(s => s.periodNumber <= 3).reduce((t: number, c: EventPeriodScore) => t + c.awayScore, 0);
  }

}
