import { MarketLayout } from '@scpc/modules/sports/components/event-top-markets/market-layout';

export const MARKETS_SOCCER: [string, MarketLayout[]] = [
  'sr:sport:1',
  [
    {
      market: { id: '1', name: '1x2' },
      outcomes: [
        { id: '1', selector: 'outcome-type-1', name: '1' },
        { id: '2', selector: 'outcome-type-2', name: 'X' },
        { id: '3', selector: 'outcome-type-3', name: '2' },
      ],
    },
    {
      market: { id: '18', name: 'Total' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' },
      ],
    },
    {
      market: { id: '16', name: 'Handicap' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' },
      ],
    },
    {
      market: { id: '113', name: 'Overtime - 1x2' },
      outcomes: [
        { id: '1', selector: 'outcome-type-1', name: '1' },
        { id: '2', selector: 'outcome-type-2', name: 'X' },
        { id: '3', selector: 'outcome-type-3', name: '2' },
      ],
      status: ['32', '41', '33', '42'],
    },
    {
      market: { id: '116', name: 'Overtime - total' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' },
      ],
      status: ['32', '41', '33', '42'],
    },
    {
      market: { id: '117', name: 'Overtime - handicap' },
      outcomes: [
        { id: '1714', selector: 'outcome-type-1714', name: '' },
        { id: '1715', selector: 'outcome-type-1715', name: '' },
      ],
      status: ['32', '41', '33', '42'],
    },
    {
      market: { id: '123', name: 'Penalty shootout - winner' },
      outcomes: [
        { id: '4', selector: 'outcome-type-4', name: '1' },
        { id: '5', selector: 'outcome-type-5', name: '2' },
      ],
      status: ['34', '50'],
      size: 3,
    },
    {
      market: { id: '127', name: 'Penalty shootout - total' },
      outcomes: [
        { id: '12', selector: 'outcome-type-12', name: '' },
        { id: '13', selector: 'outcome-type-13', name: '' },
      ],
      status: ['34', '50'],
    },
    {
      market: { id: 'm1', name: '' },
      outcomes: [
        { id: 'o1', selector: 'outcome-type-o1', name: '' },
        { id: 'o2', selector: 'outcome-type-o2', name: '' },
      ],
      status: ['34', '50'],
    },
  ],
];
