import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-basketball-3x3-live-results',
  templateUrl: './event-basketball-3x3-live-results.component.html',
  styleUrls: ['./event-basketball-3x3-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBasketball3x3LiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = ['100', '110'];

  protected hT: number;
  protected aT: number;
  protected ot: EventPeriodScore;

  public constructor() {
    super();
  }

  protected getEndedCodes(): string[] {
    return EventBasketball3x3LiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    if (this.scores.length === 2) {
      this.ot = this.scores[this.scores.length - 1];
    }
    this.hT = Number(this.matchProperties.homescore) || 0;
    this.aT = Number(this.matchProperties.awayscore) || 0;
  }

}
