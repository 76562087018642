<div class='scp-event-basketball-3x3-live-results'>
  @switch (scores.length) {
    @case (1) {
      <div class='results-grid columns-1' [class.without-border]='!border'>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    }
    @case (2) {
      <div class='results-grid columns-2' [class.without-border]='!border'>
        <span class='header'>OT</span>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='ot.homeScore > ot.awayScore'>{{ ot.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT' class='highlighted-color'>{{ hT }}</span>
        <span [class.highlighted-cell]='ot.homeScore < ot.awayScore'>{{ ot.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT' class='highlighted-color'>{{ aT }}</span>
      </div>
    }
  }
</div>
