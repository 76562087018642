@if (tournament) {
  <scp-sports-tournament-icon [sport]='event.sport'
                              [category]='event.category'
                              [tournament]='event.tournament'>
  </scp-sports-tournament-icon>
}
<div class='scp-sports-event-row' [class.mini]='mini' [class.search]='search'>
  <a [routerLink]="'/sports/events/' + event.slug"
     [state]='{events: true, tournament: event.tournament.id}'
     (scpVisibility)='onShow($event)'
     (click)='googleTagManagerService.selectSportItem(event, source)'>
    <scp-sports-event-card [event]='event'
                           [isBrowser]='isBrowser'
                           [responsive]='!mini'
                           [background]='search'>
    </scp-sports-event-card>
  </a>
  <scp-sports-event-top-markets [event]='event'
                                [mini]='mini'
                                [source]='source'
                                class='scp-sports-event-markets'>
  </scp-sports-event-top-markets>
</div>
