import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';

@Component({
  selector: 'scp-sports-event-formula-e-live-results',
  templateUrl: './event-formula-e-live-results.component.html',
  styleUrls: ['./event-formula-e-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormulaELiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES = [];

  protected getEndedCodes(): string[] {
    return EventFormulaELiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore() {
    // TODO (formula e): show results
  }

}
