import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  EventLiveResultsComponent,
} from '@scpc/modules/sports/components/event-live-results/event-live-results.component';
import { EventPeriodScore } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-event-waterpolo-live-results',
  templateUrl: './event-waterpolo-live-results.component.html',
  styleUrls: ['./event-waterpolo-live-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventWaterpoloLiveResultsComponent extends EventLiveResultsComponent {

  private static readonly MATCH_ENDED_CODES: string[] = ['100', '120', '443'];

  protected hT: number;
  protected aT: number;

  protected p1: EventPeriodScore;
  protected p2: EventPeriodScore;

  protected getEndedCodes(): string[] {
    return EventWaterpoloLiveResultsComponent.MATCH_ENDED_CODES;
  }

  protected updateScore(): void {
    this.p1 = this.scores[this.scores.length - 2];
    this.p2 = this.scores[this.scores.length - 1];
    this.hT = this.scores.filter(s => s.matchStatusCode !== 50).reduce((t: number, c: EventPeriodScore) => t + c.homeScore, 0);
    this.aT = this.scores.filter(s => s.matchStatusCode !== 50).reduce((t: number, c: EventPeriodScore) => t + c.awayScore, 0);
  }

}
