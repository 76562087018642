@if (scores?.length) {
  <div class='scp-event-darts-live-results'>
    @if (ended) {
      <div class='results-grid columns-1' [class.without-border]='!border'>
        <span class='header'>M</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    } @else {
      <div class='results-grid columns-4 float' [class.without-border]='!border'>
        <span></span>
        <span class='header'>Sc</span>
        <span class='header'>L</span>
        <span class='header'>S</span>
        <span [class.current-server]='s === "1"'></span>
        <span [class.highlighted-cell]='hC < aC' class='highlighted-color'>{{ hC }}</span>
        <span [class.highlighted-cell]='pLL.homeScore > pLL.awayScore'>{{ pLL.homeScore }}</span>
        <span [class.highlighted-cell]='hT > aT'>{{ hT }}</span>
        <span [class.current-server]='s === "2"'></span>
        <span [class.highlighted-cell]='hC > aC' class='highlighted-color'>{{ aC }}</span>
        <span [class.highlighted-cell]='pLL.homeScore < pLL.awayScore'>{{ pLL.awayScore }}</span>
        <span [class.highlighted-cell]='hT < aT'>{{ aT }}</span>
      </div>
    }
  </div>
}
